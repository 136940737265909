// src/ThreeScene.js
import React, { useState, Suspense, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Canvas, useLoader, useFrame} from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import { OBJLoader } from 'three-stdlib';


export default function ThreeScene() {

    const rotationRef = useRef([0, 0, 0]);
    const positionRef = useRef([0, 0, 0]);

    
    const [colorSkin, setColorSkin] = useState('#f4d0b0'); // Initial color
    function changeColor() {
        setColorSkin(colorSkin === '#f4d0b0' ? '#4f362b' : '#f4d0b0'); // Toggle between two colors
      }

   
    const [showModel1, setShowModel1] = useState(true);
    const toggleModel = () => {
      setShowModel1((prevShowModel1) => !prevShowModel1);

  };
  return (

    <div style={{ position: 'relative', height: '100vh' }}>

    <Canvas
        style={{
          position: 'absolute',
          top: '0px', // Adjust positioning as needed
          left: '280px',
          pointerEvents: 'none', // Prevents interference with main canvas controls
          height: '200px', // Adjust the height to fit above the main scene
          zIndex: 1, // Ensures the sphere canvas is layered above
        }}
        camera={{ position: [0, 0, 90] }}
        gl={{toneMapping: THREE.ACESFilmicToneMapping, toneMappingExposure: 0.4,}} fog={{ color: '#222222', near: 2, far: 10 }}
      >
        <Environment files="modern_bathroom.hdr" intensity={1}/>
        <Sphere />
    </Canvas>

    <Canvas
        style={{
          position: 'absolute',
          top: '0px', // Adjust positioning as needed
          left: '245px',
          pointerEvents: 'none', // Prevents interference with main canvas controls
          height: '200px', // Adjust the height to fit above the main scene
          zIndex: 1, // Ensures the sphere canvas is layered above
        }}
        camera={{ position: [0, 0, 90] }}
        gl={{toneMapping: THREE.ACESFilmicToneMapping, toneMappingExposure: 0.4,}} fog={{ color: '#222222', near: 2, far: 10 }}
      >
        <Environment files="modern_bathroom.hdr" intensity={1}/>
        <Sphere2 />
    </Canvas>



    
    <Canvas camera={{ position: [0, 20, 30] }} style={{ height: '100vh', backgroundColor: '#222222' }} gl={{toneMapping: THREE.ACESFilmicToneMapping, toneMappingExposure: 0.4,}} fog={{ color: '#222222', near: 2, far: 10 }}>
      {/* Lighting */}

      
      
      <Environment files="modern_bathroom.hdr" intensity={0}/>


        <Suspense fallback={null}>
          {showModel1 ? <Model1 key="model-one" colorSkin={colorSkin} rotationRef={rotationRef}
              positionRef={positionRef}/> : <Model2 key="model-two" colorSkin={colorSkin} rotationRef={rotationRef}
              positionRef={positionRef}/>}
        </Suspense>

      {/* Camera Controls */}
      <OrbitControls enableZoom={true} />
    </Canvas>

    <div
        onClick={toggleModel}
            style={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            padding: '10px 20px',
            fontFamily: 'serif',
            color: 'white',
            fontWeight: 'bold',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            }}
    >
    ♀ - ♂    
    </div>

    <div
    onClick={changeColor}
        style={{
            width: '30px',
            height: '15px',
        position: 'absolute',
        top: '80px',
        right: '20px',
        padding: '10px 20px',
        fontFamily: 'serif',
        color: 'white',
        fontWeight: 'bold',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        }}
    >
       
    </div>

    <div
            style={{
            position: 'absolute',
            top: '20px',
            left: '20px',
            fontFamily: 'serif',
            fontWeight: 'bold',
            padding: '10px 20px',
            color: "white",
            border: 'none',
            cursor: 'pointer',
            }}
    >
    1UMPEN
    </div>
    </div>                 
  );
}

function Sphere() {
    // Define material directly within the component
    const material = new THREE.MeshPhysicalMaterial({
      color: '#4f362b', // Fixed color for the sphere
      roughness: 0.6,
      metalness: 0,
      transmission: 0.01,
      clearcoat: 0.05,
      clearcoatRoughness: 0.7,
      thickness: 0.1,
      attenuationDistance: 0.3,
      attenuationColor: '#ffb199',
      emissive: '#b98e82',
      emissiveIntensity: 0.1,
    });
  
    return (
      <mesh material={material} position={[0, 0, 0]}>
        <sphereGeometry args={[10, 30, 30]} />
      </mesh>
    );
  }

  function Sphere2() {
    // Define material directly within the component
    const material = new THREE.MeshPhysicalMaterial({
      color: '#f4d0b0', // Fixed color for the sphere
      roughness: 0.6,
      metalness: 0,
      transmission: 0.01,
      clearcoat: 0.05,
      clearcoatRoughness: 0.7,
      thickness: 0.1,
      attenuationDistance: 0.3,
      attenuationColor: '#ffb199',
      emissive: '#b98e82',
      emissiveIntensity: 0.1,
    });
  
    return (
      <mesh material={material} position={[0, 0, 0]}>
        <sphereGeometry args={[10, 30, 30]} />
      </mesh>
    );
  }

const Model1 = React.memo(function Model1({ colorSkin, rotationRef, positionRef }) {
    const originalObj = useLoader(OBJLoader, 'FemaleBaseMesh.obj');
    const obj = originalObj.clone();
    
    useEffect(() => {
      applySkinMaterial(obj, colorSkin);
      obj.rotation.set(...rotationRef.current);
      obj.position.set(...positionRef.current);

    }, [obj, colorSkin, rotationRef, positionRef]); // Re-apply material when `colorSkin` changes

    useFrame(() => {
        rotationRef.current = [obj.rotation.x, obj.rotation.y, obj.rotation.z];
        positionRef.current = [obj.position.x, obj.position.y, obj.position.z];
      });
  
    return <primitive key="female-mesh"object={obj} scale={10} />;
  });
  
const Model2 = React.memo(function Model2({ colorSkin, rotationRef, positionRef }) {
    const originalObj = useLoader(OBJLoader, 'MaleBaseMesh.obj');
    const obj = originalObj.clone();
  
    useEffect(() => {
      applySkinMaterial(obj, colorSkin);
      obj.rotation.set(...rotationRef.current);
      obj.position.set(...positionRef.current);


    }, [obj, colorSkin, rotationRef, positionRef]);

    useFrame(() => {
        rotationRef.current = [obj.rotation.x, obj.rotation.y, obj.rotation.z];
        positionRef.current = [obj.position.x, obj.position.y, obj.position.z];
      });  
  
    return <primitive key="male-Mesh" object={obj} scale={9.5} />;
  });

export { Model1, Model2 };
    
function applySkinMaterial(obj, colorSkin) {

  obj.traverse((child) => {
    if (child.isMesh) {
      child.material = new THREE.MeshPhysicalMaterial({
        color: colorSkin,  
        //color: '#4f362b',           // Softer skin tone
        roughness: 0.6,
        metalness: 0,
        transmission: 0.01,            // Lowered to reduce transparency
        clearcoat: 0.05,
        clearcoatRoughness: 0.7,
        thickness: 0.1,               // Controls subsurface scattering depth
        attenuationDistance: 0.3,     // Adjust for light falloff in the material
        attenuationColor: '#ffb199',  // Red hue for more natural skin scattering
        emissive: '#b98e82',          // Adds a warm undertone
        emissiveIntensity: 0.1   // Controls the roughness of the clearcoat layer
      });
    }
  });
}

  
  
